

export function extractMiddlewareValues(middlewareData){
  // used for extracting only the data that changes inside the Middleware Object, like Register Value and Source
  // composes an Array of that, it's used to compare states - checking if at time B the Middleware is in the same state as it was in time A
  const REGISTERS = ["register_1", "register_2", "register_destiny"]
  if (!middlewareData){
    return 
  }

  function getRegisterData(agent, register){
    let v = null;    
    if ((typeof agent[register]?.value) == undefined){ 
      v = null
    } else {
      v = agent[register]?.value
    }

    let s = null;    
    if ((typeof agent[register]?.source) == undefined){ 
      s = null
    } else {
      s = agent[register]?.source
    }


    if (s == null){
      return null
    }
    if (v == null){
      v = ""
    }
    return `${v}+${s}` 
  }
  
  let result = []

  Object.values(middlewareData).forEach(agent => {
    if (Array.isArray(agent)) {

      agent.forEach(inner => {
        REGISTERS.forEach(reg => {
          let t = getRegisterData(inner, reg)
          if (t != null){
            result.push(t)
          }
        })
      });

    } else if (typeof agent == 'object') {

      REGISTERS.forEach(reg => {
        let t = getRegisterData(agent, reg)
        if (t != null){
          result.push(t)
        }
      })
    
    }
  });
  
  return JSON.stringify(result)
}

export function getValueFrom(options, agents) {
  let data = undefined;
  let value = null;


  agents.forEach((element) => {
    const check_for_value = options.value != undefined;

    if (check_for_value) {
      value = element[options.from.value].value;
      if (options.modifier) {
        value = options.modifier(value);
      }
    }

    //----------------------------------------------
    // this is a special addition to the "getValueFrom" function, in almost all other Middlewares it's the same, but here there's a caviat - 'confirmationFunction'
    //if these are ever generalized in a single file this section should be in - it's made to not affect anything that doesn't use it
    let confirm = true;
    if (options.confirmationFunction){
      confirm = options.confirmationFunction(element)
    }//----------------------------------------------


    if (
      element[options.from.source].source == options.source &&
      (!check_for_value || value == options.value) &&
      confirm
    ) {
      if (options.data_modifier) {
        element = options.data_modifier(element);
      }

      if (!options.multiple) {
        data = element;
        return;
      }
      if (data == undefined) data = [];

      data.push(element);
    }
  });

  if (data == undefined) {
    data = options.default;
  }

  return data;
}
