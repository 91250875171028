<template>
<middleware-sidebar-outline
  :uuid="uuid"
  :middlewareID="middlewareID"
  :middlewareType="middlewareType"
  ref="middleware-sidebar-outline"
  @allowClosing="allowForceClosing = true"
  @close="closingSidebar"
  @shown="$emit('shown')"
  @saveMiddleware="buildAgents"
  v-model="variables"
>
  <div>
    <b-card bg-variant="dark-blue" body-class="p-0 m-1" class="control m-1">
      <b-container>
        <b-row class="d-flex justify-content-center mt-1">
          <b-col cols="12">
            <h3 class="text-primary font-weight-bolder">
              FOR (
            </h3>
          </b-col>
          <b-col cols="12" class="mb-1 mt-2 d-flex justify-content-between">
            <div class="w-25">
              <label for="custom-input-from" class="mb">Counter <helper-tooltip size="13" tooltipPlacement="right"/></label>
              <b-form-input
                v-if="variables"
                id="index-name"
                v-model="forData.index_name.register_1.value"
              />
            </div>
            <h3 class="mt-2 pt-50">
              &nbsp;=&nbsp;
            </h3>
            <div class="w-50 mx-25">
              <custom-input
                v-if="variables"
                id="custom-input-from"
                v-model="forData.from.register_1"
                :possibleValues="variables"
              >
                <template #label>
                  From <helper-tooltip size="13" tooltipPlacement="right"/>
                </template>
              </custom-input>
            </div>
            <feather-icon
              style="margin-top: 28px;"
              icon="ArrowRightIcon"
              size="25"
            />
            <div class="w-50 mx-25">
              <custom-input
                v-if="variables"
                id="custom-input-to"
                v-model="forData.to.register_1"
                :possibleValues="variables"
              >
                <template #label>
                  To <helper-tooltip size="13" tooltipPlacement="right"/>
                </template>
              </custom-input>  
            </div>
            <div class="w-25 mx-25">
              <custom-input
                v-if="variables"
                id="custom-input-step"
                v-model="forData.step.register_1"
                :possibleValues="variables"
              >
                <template #label>
                  Step <helper-tooltip size="13" tooltipPlacement="right"/>
                </template>
              </custom-input>
            </div>
          </b-col>
        </b-row>
        <h3 class="float-right font-weight-bolder text-primary">)</h3>
      </b-container>
    </b-card>
    <div class="text-secondary mx-2 example-code-container"> 
      you can access the iterated count through "$.@counter_name":
      <div class="mb-1 mt-50">
        <div class="">
          <span class="mr-3"><b>Example</b>:</span>
          <span class="mr-3">
            Counter <b-icon icon="arrow-right-short" scale="1.1" /> "idx"
          </span>
          <span class="mr-3">
            From <b-icon icon="arrow-right-short" scale="1.1" /> 0
          </span>
          <span class="mr-3">
            To <b-icon icon="arrow-right-short" scale="1.1" /> 3
          </span>
          <span>
            Step <b-icon icon="arrow-right-short" scale="1.1" /> 1
          </span>
        </div>
        <div class="mx-2 mt-25 rounded px-1 py-25 text-secondary d-flex justify-content-start align-items-center">
            <div v-for="i in 3" class="mr-1 border-left pl-50" :key="'foreach-example-'+i">
                <div>On iteration {{i}}: </div>
                <div class="ml-50">
                  <span class="font-weight-bold" v-pre>{{$.@idx}}  </span> <b-icon icon="arrow-right-short" scale="1.1" /> <span class="font-weight-bolder">{{i-1}}</span>
                </div>
            </div>
        </div>
        </div>
    </div>
  </div>
</middleware-sidebar-outline>
</template>

<script>
import { 
  BPopover,
  BIcon,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BFormInput,
  BContainer,
  BSidebar,
  BSpinner,
  BCard
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VariablesPanel from "../VariablesPanel.vue";
import { makeToast } from '@/layouts/components/Popups'
import CustomInput from "../CustomInput.vue";
import HelperTooltip from '@/layouts/components/HelperTooltip.vue';
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent.js'

import Middlewares from "@/custom/class/Enum/Middlewares.js"
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"


import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'


  export default {
    mixins: [MiddlewaresMixin],
    components: {
      BPopover,
      BSidebar,
      BIcon,
      BButton,
      BRow,
      BFormGroup,
      BCol,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BContainer,
      VuePerfectScrollbar,
      VariablesPanel,
      BSpinner,
      CustomInput,
      HelperTooltip,
      BCard,
      MiddlewareSidebarOutline
    },
    props: {
      i18nKey: {
        type: String,
        default: "for"
      },
      middlewareID: {
        type: Number,
        required: true,
      },
      uuid: {
        type: String,
        required: true,
      },
      value: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        uuidMap: {},
        variables: undefined,
        variant_map: undefined,
        agentList: [],
        isSaving: false,
        middlewareType: new Middlewares().items.find(el=> el.id == 14),
        startData: undefined,
        allowForceClosing: false, 
        forData: {
          index_name: undefined,
          from: undefined,
          to: undefined,
          step: undefined,
        },

        nameField:{
          index_name: 'INDEX_NAME',
          from: 'FROM',
          to: 'TO',
          step: 'STEP',
        },

        defaultValue: { 
          index_name: undefined,
          from: undefined,
          to: undefined,
          step: '1',
        },

        defaultAgentBlockID: 1,
        defaultAgentActionID: 3
      }
    },
    computed: {
      agents: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.initializeAgents()
        this.cloneStart()
      },
      cloneStart(){
        this.startData = MiddlewareFunctions.extractMiddlewareValues(this.forData)
      },
      configureVars() {
        this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
        this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
      },
      isObject(item) {
        return item instanceof Object && !(item instanceof Array);
      },
      isArray(item) {
        return item instanceof Array;
      },
      buildAgents() {
        this.isSaving = true;
        this.agentList = [];

        Object.keys(this.forData).forEach((key) => {
          this.agentList.push(DefaultAgent.defineToAPI(this.forData[key], this.middlewareID));
        })

        this.saveAgents();
      },
      saveAgents() {
        this.$store
          .dispatch("saveAgents", {
            agentList: this.agentList,
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {
            makeToast({
              title: this.$t("agent.toast.create_agents.success.title"),
              text: this.$t("agent.toast.create_agents.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
            this.$emit("saved", response.data);
          })
          .catch((error) => {
          });
      },
      initializeAgents() {

        Object.keys(this.forData).forEach((key) => {
          this.forData[key] = MiddlewareFunctions.getValueFrom(
            {
              default: DefaultAgent.setAgent(this, {
                id: null,
                enum_agent_action_id: this.defaultAgentActionID,
                enum_agent_block_id: this.defaultAgentBlockID,
                register_1: {
                  source: "7",
                  value: this.defaultValue[key] || undefined
                },
                register_2: {},
                register_destiny: {
                  source: "15",
                  value: this.nameField[key],
                },
                execution_order: 1,
                fatal_on_fail: true,
              }),
              source: "15",
              value: this.nameField[key],
              from: {
                source: "register_destiny",
                value: "register_destiny",
              },
              modifier: false,
              multiple: false,
              expected: {
                source: "7",
                from: "register_1",
              },
            },
            this.agents
          );
        })
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      sidebarShown() {
        this.$emit("shown")
        this.isSaving = false;

        let el = document.getElementsByTagName("html");
        el[0].classList.add("hide-scrollbar");
      },
      sidebarHidden() {
        this.$emit("close");

        let el = document.getElementsByTagName("html");
        el[0].classList.remove("hide-scrollbar");
      },
      toggleSidebar() {
        this.$root.$emit("bv::toggle::collapse", this.uuid);
      },
      closingSidebar(){
        if (this.isSaving || this.allowForceClosing){
          this.$emit('close')
          return
        }
        let newValues = MiddlewareFunctions.extractMiddlewareValues(this.forData)
        if (!this.isSaving && (this.startData) != (newValues)){
          
          this.$root.$emit("bv::toggle::collapse", this.uuid );
          this.$refs['middleware-sidebar-outline'].showConfirmClose()
          
          // this.cloneStart()
        } else {
          this.$emit('close')
        }
      }
    }

  }
</script>


<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }


.example-code-container{
  font-family: 'Lucida Sans';
  letter-spacing: 0.5px;
  opacity: 0.7;
}
  
</style>


<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow: auto;

    min-height: 80%;
    padding-bottom: 20px;
  }

  .limit-height {
    height: calc(100% - 215px) !important;
  }

  .saving-adjust-text-position {
    position: relative;
    top: 2px;
  }
}
</style>